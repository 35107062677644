@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --bkg-green: rgb(135, 255, 201);
  --bkg-blue: #134468;
  --bkg-cyan: #40fff2;
  --bkg-yellow: #ffcf55;
  --table-grey: #efefef;
  --accent-grey: hsl(0, 10%, 73%);
  --accent-green: #24b790;
  --small-border: 3px;
  --medium-border: 5px;
  --large-border: 8px;
  --card-shadows-main: 1px 2px 2px 1px rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
  --card-shadows-large: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);

  --primary-blue: #134468;
  --secondary-blue: #00355b;
  --primary-green: #88ffc8;
  --secondary-green: #57ffb1;
  --accent-green: #25ae88;
  --primary-yellow: #ffcf51;
  --secondary-yellow: #fbc025;
  --primary-cyan: #41fff2;
  --secondary-cyan: #1ef8e9;
  --accent-blue: #00c7b9;
  --accent-blue2: #05c1b4;
}

p,
h1,
h2,
h3,
h4 {
  color: var(--primary-blue) !important;
}

/* @font-face {
  font-family: "Muli" !important;
  src: BaseUrl("/public/assets/muli/Muli.ttf") format("truetype") !important;
} */

.terms-styles h4 {
  color: #25ae88 !important;
  margin-top: 30px;
  font-weight: 500 !important;
}

.terms-styles {
  font-size: 16px;
}

body,
html {
  height: 100%;
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "Raleway", sans-serif !important;
  /* background-color: #fff !important; */
}

.c-main {
  background-color: #fff !important;
}

.header-drop-inner {
  background-color: #dfe7f0;
  font-size: 12px;
}

.userId-drop-item {
  background-color: #fbfbfb;
}
.c-header-nav-items {
  margin-right: 60px;
}
.c-avatar {
  height: 42px;
  width: auto;
  background-color: var(--primary-blue);
  padding: 5px 15px 5px 5px !important;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}
.c-avatar:hover {
  background-color: #00355be0;
}
.c-avatar-img {
  width: auto;
  height: 36px;
  margin-right: 5px;
}

.back-button {
  font-weight: 300;
  position: fixed;
  z-index: 2000;
  top: 20px;
  left: 120px;
  font-size: 16px;
  color: #00355b;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  transition: 0.6s ease-in;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  -webkit-transition: 0.6s ease-in;
  -moz-transition: 0.6s ease-in;
  -ms-transition: 0.6s ease-in;
  -o-transition: 0.6s ease-in;
}

.back-button:hover {
  background-color: var(--bkg-green);
}

@media screen and (max-width: 767px) {
  .back-button {
    left: 20px;
    box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
      0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
  }
  .c-header-nav-items {
    margin-right: 12px;
  }
}

/* .c-header-nav-items {
  margin-right: 120px !important;
  padding: 5px;
  background-color: var(--primary-blue);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
} */
.header-av-name {
  padding: 3px 10px 3px 20px;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-weight: 700;
  color: #00355b;
  display: inline-block !important;
  position: absolute;
  left: 20px;
  z-index: -10;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.card {
  border: none;
  background-clip: border-box;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}
.link-btn {
  text-decoration: none;
}
.register-main-title {
  font-size: 18px;
  font-weight: 800;
  color: #403e54;
}
.reg-card-main:hover {
  /*overflow: hidden;
  */
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
.reg-card-main {
  padding: 14px;
  transition: 0.7s ease;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -ms-transition: 0.7s ease;
  -o-transition: 0.7s ease;
}
.reg-title {
  margin: 14px 0;
  color: #403e54 !important;
  font-size: 18px;
  font-weight: 700;
}
.reg-card p {
  /* font-size: 17px; */
  font-weight: 400;
  color: #a8a8a8 !important;
  margin-top: 20px;
}
.reg-card img {
}
.register-btn {
  /*display: block;
  */
  margin: 10px 0px;
  width: 100%;
  color: #fff;
  background-color: var(--primary-blue);
  padding: 8px;
  border: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
/* .card:hover {
  box-shadow: 0px 5px 15px 1px rgba(69, 65, 78, 0.06) !important;
} */
.link-card:hover {
  text-decoration: none;
  transition: all 0.3s ease !important;
  box-shadow: 0px 1px 21px 1px rgba(69, 65, 78, 0.12) !important;
  /* box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.06) !important; */
}

.c-sidebar {
  color: #fff;
  background: var(--primary-blue) !important;
}
.c-sidebar-nav {
  background: #134468 !important;
}

.c-sidebar .c-sidebar-brand {
  background: none;
}

.c-sidebar-nav-item,
.c-sidebar-nav-dropdown {
  /* margin: 6px 0; */
  padding: 10px;
  background-color: #00355b23;
  font-size: 14px;
  font-weight: 600;
}

.c-sidebar-nav-link {
  padding: 5px;
  letter-spacing: 1.1 !important;
  border-radius: 10px;
}

.c-sidebar-nav-dropdown-toggle {
  padding: 5px;
  padding-right: 10px;
  letter-spacing: 1.1 !important;
  border-radius: 10px;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background-color: #00355b !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background-color: #00355b8a !important;
}

.dashboard-cards a {
  text-decoration: none;
}

.block-button-main {
  width: 100%;
  border: 0px;
  height: 50px;
  border-radius: var(--small-border);
  padding: 7px 20px !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.9px;
  word-spacing: 5px;
  background-color: #134468 !important;
}
.block-button-danger {
  width: 100%;
  border: 0px;
  border-radius: var(--small-border);
  padding: 10px 20px !important;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.9px;
  word-spacing: 5px;
  background-color: #ff0000;
}
.block-button-danger:hover {
  color: #e6fcff;
  background-color: #ff0000;
}

.c-sidebar .c-sidebar-brand {
  text-decoration: none;
}

.brand-type {
  margin-top: 5px;
  letter-spacing: 1.2px;
  background-color: var(--accent-green);
  padding: 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: var(--small-border);
  /* text-decoration: none; */
}

/* .card {
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px 1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
} */

.truckIcon .dropdown-toggle::after {
  content: none;
}

.block-button-main:hover {
  background-color: var(--secondary-blue);
  color: #fff;
}

.main-primary-button {
  border: 0px;
  border-radius: var(--small-border);
  padding: 8px 20px;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--primary-blue);
}
.main-primary-button:hover {
  background-color: var(--secondary-blue);
  color: #fff;
}

.page-title {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-blue);
  margin: 20px 0px;
}
.section-header-title {
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--primary-blue);
  margin: 30px 0px 10px;
}
.background-light-green {
  background-color: #d5fff5;
}
.text-theme-blue {
  color: var(--primary-blue);
}
/* .text- */
.dashboard-cards .card {
  height: 160px;
}
.card-yellow {
  background-color: var(--primary-yellow) !important;
}
.card-green {
  background-color: var(--primary-green) !important;
}
.card-blue {
  border-radius: 6px;
  background-color: var(--primary-blue) !important;
}
.card-cyan {
  background-color: var(--primary-cyan) !important;
}
.card-light-cyan {
  background-color: #e6fcff;
}
.card-blue-secondary {
  background-color: var(--accent-blue) !important;
}

.card-inner {
  padding: 10px 20px;
  border-radius: var(--small-border);
  font-size: 13px;
  font-weight: 700;
}
.card-evacuation-text {
  font-size: 16px;
  font-weight: 600;
}
.card-evacuation-inner {
  background-color: var(--primary-green);
  border-radius: var(--small-border);
  width: 100%;
  font-weight: 600;
  margin-top: 5px;
  padding: 7px 20px;
  text-align: center;
}

.card-inner-yellow {
  background-color: var(--secondary-yellow);
}

.card-inner-blue {
  background-color: var(--secondary-blue);
}
.card-inner-green {
  background-color: var(--secondary-green);
}
.card-inner-cyan {
  background-color: var(--accent-blue2);
}

.text-default {
  color: #212121 !important;
}

.card-text-container {
  background-color: var(--primary-yellow);
  color: var(--primary-blue);
  padding: 5px 20px;
  display: inline-block;
  font-size: 1.3125rem;
  font-weight: 600;
  height: 40px;
  border-radius: var(--small-border);
  align-items: center;
  /* padding: 5px; */
}

.text-white {
  color: #fff !important;
}

.property-card-height {
  min-height: 150px;
}

.property-links .property-card-height:hover {
  box-shadow: 0 1px 15px rgb(0 0 0 / 15%) !important;
}

.property-links:hover {
  text-decoration: none;
}

.activity-card {
  padding-left: 40px 40px;
  text-align: left;
}
.activity-card button {
  background-color: #05c1b4;
  color: white;
  font-weight: 500;
  padding: 12px 40px;
  border-radius: var(--small-border);
  /* color: var(--primary-blue); */
  text-transform: capitalize;
  border: 0px;
}

.add-property-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
}

.last-evac-info {
  color: var(--primary-blue);
  font-weight: 600;
}
.last-evac-info span {
  background-color: var(--primary-green);
  padding: 10px 20px;
  font-weight: 700;
  border-radius: var(--small-border);
  margin-left: 10px;
}
.avatar-select {
  border: 5px solid;
  border-color: #b5b5b5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* property page styles */
.property-cards .card {
  height: 150px;
  box-shadow: var(--card-shadows-main);
  /* max-height: 200px !important; */
}

.property-cards .property-cards-inner {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.property-card-button {
  padding: 10px 15px 12px;
  background-color: var(--accent-green);
  border-radius: var(--medium-border);
  color: #fff;
  transition: 0.5s all;
}
/* .property-card-button:hover {
  box-shadow: --csa;
 
} */

.property-cards .p-card-text {
  font-size: 18px;
  font-weight: 600 !important;
}

.add-property-button {
  padding: 10px 20px 15px;
  background-color: #82fec8;
  border-radius: var(--medium-border);
  color: #fff;
  transition: 0.6s all;
}

.property-card-button img {
  color: #fff;
  transform: rotate(-90deg);
}
.rotated-go-icon {
  /* padding: 10px; */
  margin-top: 5px;
  /* background-color: #fff; */
  border-radius: 2px;
}
.rotated-go-icon img {
  color: var(--primary-blue);
  transform: rotate(-90deg);
}

/* details page styles  */
.details-list {
  display: flex;
  text-align: left;
  padding: 8px 20px;
  border-bottom: 1px solid hsl(210, 11%, 70%);
}

.detail-type {
  flex: 2;
  font-weight: 500;
  color: #a8a8a8;
}
.detail-info {
  flex: 1;
  font-weight: 900;
  text-transform: capitalize;
  color: var(--primary-blue);
}
.custom-select-blue {
  width: 100%;
  padding: 10px;
  margin-bottom: 7px;
  border: none;
  border-radius: 3px;
  background-color: var(--primary-blue);
  color: white;
  font-weight: 700;
  outline: none;
}
.custom-select-blue option {
  padding: 10px 5px;
  height: 20px;
}

.standard-card {
  /* width: 100% !important;
  display: flex !important; */
  align-items: center;
  background-color: #dbffff;
  padding: 10px 5px;
}

.premium-card {
  /* width: 100% !important;
  display: flex !important; */
  align-items: center;
  background-color: #ffcf51;
  padding: 10px 5px;
}

.price-card {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  color: var(--primary-blue);
}

/* .price-text-container {
  flex: 2;
} */
.premium-card .price-card-title {
  color: #4a00cc;
}
.price-card-title {
  font-size: 1.2rem;
  font-weight: 700;
}
.price-card-text {
  /* font-size: ; */
}
.standard-card .price-card-amount {
  background-color: #e6fcff;
  /* box-shadow: var(--card-shadows-main); */
}

.price-card-amount {
  font-weight: 700;
  font-family: "Mulish !important";
  padding: 10px 25px;
  background-color: #ffeab4;
  border-radius: var(--small-border);
  box-shadow: var(--card-shadows-main);
}

.form-styles {
  color: var(--primary-blue) !important;
}
.form-styles label {
  /* color: var(--secondary-blue) !important; */
  font-weight: 800;
}

input[type="radio"] {
  -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
  -moz-appearance: checkbox; /* Firefox */
  -ms-appearance: checkbox; /* not currently supported */
  appearance: checkbox;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-type {
  flex: 1;
}

.create-account {
  flex: 1;
}

.p4c-card {
  background-color: #25ae88;
}
.p4p-card {
  background-color: #82fec8;
}

.reg-type {
  padding: 7px;
  font-size: 12px;
  font-weight: 800;
  color: var(--primary-blue);
  border-radius: var(--small-border);
  background-color: #e6fcff;
  margin: 0px 15px;
  letter-spacing: 1px;
}
.reg-type-2 {
  padding: 7px;
  font-size: 12px;
  font-weight: 800;
  color: #e6fcff;
  border-radius: var(--small-border);
  background-color: var(--primary-blue);
  margin: 0px 15px;
  letter-spacing: 1px;
}
.login-label {
  color: var(--primary-blue) !important;
  font-weight: 500;
}
.login-title {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.56px;
  color: #25ae88 !important;
  text-align: center;
}
.login-policy-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-blue) !important;
}
/* .login-policy-text span a {
  color: #25ae88 !important;
} */

.register-hint {
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  color: #fff !important;
}
.register-hint span {
  color: #25ae88 !important;
}

.profile-detail-title {
  text-align: center;
  font-weight: 800;
  color: var(--primary-blue);
  margin: 40px 0px;
}
.details-form h6 {
  color: var(--primary-blue);
  font-weight: 700;
}
.details-form label {
  color: #949ba1;
}

/* profile page styles  */
.profile-inputs h6,
h5 {
  text-transform: capitalize !important;
  font-weight: 900 !important;
  letter-spacing: 0.05rem;
}

.profile-inputs input {
  border: 0.2px solid #13456869 !important;
  padding: 20px 10px;
  border-radius: 2px;
  /* border-color: var(--primary-blue) !important; */
}
.profile-inputs label {
  font-size: 12px;
}

/* profile details style ends */

/* reports page styles start */

.reports-card-icon {
  padding: 10px 15px 12px;
  background-color: #fff;
  border-radius: 3px;
}

.csr-amount {
  color: var(--primary-blue);
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--secondary-green);
  border-radius: 2px;
  padding: 5px 20px;
}

@media screen and (max-width: 476px) {
  .profile-inputs form {
    padding: 10px 5px !important;
  }
  .last-evac-info {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}

/* custom property styling */

.custom-search {
  position: relative;
  /* box-shadow: var(--card-shadows-main); */
}
.custom-search input {
  border-radius: var(--small-border) !important;
  padding: 10px 30px 10px 40px !important;
  width: 100%;
  border: 0.4px;
  transition: 0.7s all;
}
.custom-search input:focus {
  outline: none;
  border-color: var(--primary-blue) !important;
}
.custom-search span {
  position: absolute;
  left: 10px;
  top: 10px;
}

/* end custom property styling */

/* table button  */
.table-button {
  /* font-size: 10px; */
  color: white;
  background-color: var(--primary-blue);
  /* display: block; */
  /* padding: 10px 30px; */
  /* border-radius: var(--small-border); */
  /* text-transform: uppercase; */
}
.table-button:hover {
  background-color: var(--secondary-blue);
  color: white;
}

.page-item.active .page-link {
  background-color: var(--accent-green);
  border-color: var(--accent-green);
}
.breadcrumb a {
  color: var(--primary-blue);
  font-weight: 600;
}
/* .breadcrumb .breadcrumb-item {
  font-weight: 600;
} */

.showSchedule {
  display: block;
  /* transition: top 2s ease-in-out;
  -webkit-transition: 2s top ease-in-out;
  -moz-transition: 2s top ease-in-out;
  -ms-transition: 2s top ease-in-out;
  -o-transition: 2s top ease-in-out; */
}
.dontShow {
  display: none;
  position: relative;
  transition: all 2s ease 0s;
  top: 0;
  /* transition: top 2s ease-in-out;
  -webkit-transition: 2s top ease-in-out;
  -moz-transition: 2s top ease-in-out;
  -ms-transition: 2s top ease-in-out;
  -o-transition: 2s top ease-in-out; */
}

.truckIcon {
  padding: 5px;
  cursor: pointer;
}

.nav-tabs {
}
.nav-tabs .nav-link {
  color: #00355b;
  font-weight: 600;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #11d5b4;
  font-weight: 600;
  border: 0px;
  border-bottom: 2.5px solid;
  border-color: #11d5b4;
  background-color: transparent;
}
/* modal header  */
.modal-bg-blue .close {
  color: #fff;
}

.modal-bg-blue {
  background-color: var(--primary-blue);
  color: white;
  font-weight: 600;
  align-items: center;
}

/* spinloader */
.spinLoader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loader-text {
  font-size: 15px;
  color: #00355b;
  font-weight: 500;
}

/* spinloader */
/* .spinLoader {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 100;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */

/* loader screen style */
.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255);
}

.loader-wrapper {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  animation: spin 2s ease-in-out infinite;
}

/* modal header */
.modal-header-blue .close {
  color: #fff;
}

.modal-header-blue {
  background-color: var(--primary-blue);
  color: white;
  font-weight: 600;
}

/* evacuation aside */
.aside-container {
  position: sticky;
  width: 100%;
  top: 120px;
}

.wallet-balance-container {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
  background-color: #25ae88;
  color: #fff !important;
  text-align: center;
  /* font-size: 16px; */
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.card-header-logo {
  background-color: var(--bkg-blue);
  padding: 10px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

/* evacuation price styles */
.aside-header {
  background-color: var(--bkg-blue);
  padding: 10px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.priceBox {
  padding: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0px;
  border-radius: 2px;
  background-color: #25ae88;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.priceBoxBlue {
  padding: 10px;
  font-family: "Mulish", sans-serif;
  color: #fff;
  font-size: 16px !important;
  font-weight: 600;
  margin: 10px 0px;
  border-radius: 2px;
  font-size: 15px;
  background-color: var(--primary-blue);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.contact-us-link {
  color: var(--primary-blue);
}

.contact-us-link:hover {
  color: #05c1b4;
}

.verify-card {
  background-color: var(--primary-blue);
  color: #fff;
}

.verify-card h4 {
  /* background-color: var(--primary-blue); */
  color: #fff !important;
  font-weight: 200;
  margin: 20px 0px;
}

.verify-card .card-body {
  padding: 50px 30px !important;
}

.verify-card button {
  margin-top: 20px;
  border-color: #00355b;
  color: #00355b;
  background-color: #fff;
}

.verify-card button:hover {
  background-color: #25ae88;
  border-color: #fff;
  color: #fff;
  transition: 1s all;
  -webkit-transition: 1s all;
  -moz-transition: 1s all;
  -ms-transition: 1s all;
  -o-transition: 1s all;
}

/* truck additional data  */
.tripSubData .partnerStyle {
  text-align: center;
  color: #fff;
  background-color: #00355b;
  font-weight: 600;
  border: 1px #b5b5b5 solid;
}
.tripSubData .evacStyle {
  color: white;
  background-color: #11d5b4;
  font-weight: 600;
}

.tripSubData small {
  color: #00355b;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}
.tripSubData div {
  padding: 8px 20px;
  display: inline-block;
}
.tripSubData .trips-total {
  color: #fff;
  background-color: #00355b;
  font-weight: 600;
  font-size: 14px;
}
.tripSubData .success-trips {
  color: white;
  background-color: #11d5b4;
  font-weight: 600;
  font-size: 14px;
}
.tripSubData .cancelled-trips {
  color: #ff0303;
  background-color: #fff0ec;
  font-weight: 600;
  font-size: 14px;
}

.subData small {
  color: #00355b;
  font-weight: 600;
  text-transform: capitalize;
}

.subData .sub-content {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #00355b;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #cefff8;
}

.estateTitle {
  padding: 30px;
  text-align: center;
  color: #00355b;
  background-color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
}

.sidebar-icon {
  padding: 10px 13px;
  margin-left: 5px;
  margin-right: 16px;
  /* display: inline-block; */
  /* background-color: #fff; */
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.props-table table {
  margin-top: 20px;
}
.props-table thead {
  background-color: #00355b;
  border-radius: 4px 4px !important;
  color: #fff;
  font-weight: 700 !important;
  font-size: 15px !important;
  -webkit-border-radius: 4px 4px !important;
  -moz-border-radius: 4px 4px !important;
  -ms-border-radius: 4px 4px !important;
  -o-border-radius: 4px 4px !important;
}
.props-table tbody {
  color: #00355b !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}
.pType {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.registerLogo {
  text-align: center;
  background-color: #00355b;
  padding: 30px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.y-bg {
  background-image: url("/backgrounds/yellow-bg-img.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center center;
}

.g-bg {
  background-image: url("/backgrounds/green-bg-img.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center center;
}
.b-bg {
  background-image: url("/backgrounds/blue-bg-img.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center center;
}

.white-bgs {
  background-image: url("/backgrounds/white-bg-img.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center center;
}

.card-img-bg {
  background-image: url("/backgrounds/card-bg-img.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;
}

.status-style {
  background-image: url("/layouts/status-card.png");
  background-position: bottom;
  background-size: 100%;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  /* min-width: 100%; */
}

.dashboardCards {
  padding: 15px;
  min-height: 156px;
  margin-bottom: 20px;
  border-radius: 6px;
  color: #fff;
  background-color: #134468;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 345px;
}
.csr-card {
  display: flex;
  min-width: 345px;
}

@media screen and (max-width: 767px) {
  .dashboardCards {
    width: 100%;
  }
  .csr-card {
    width: 100%;
  }
}

.fl-bg-1 {
  background-repeat: no-repeat;
  background-position: right;
  background-image: url("/pooplogg/flower/1.png");
}
.fl-bg-2 {
  background-repeat: no-repeat;
  background-position: right;
  background-image: url("/pooplogg/flower/2.png");
}
.fl-bg-3 {
  background-repeat: no-repeat;
  background-position: right;
  background-image: url("/pooplogg/flower/3.png");
}

.d-card-title {
  font-size: 16px;
  font-weight: 700;
  /* margin-top: 20px; */
  margin-bottom: 10px;
}

.d-card-block1 {
  padding: 12px 16px;
  color: #002541;
  background-color: #fdefcb;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  background: src("/pooplogg/flower/3.png");
}
.d-card-block2 {
  padding: 12px 16px;
  color: #002541;
  background-color: #d3fffc;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  background: src("/pooplogg/flower/1.png");
}
.d-card-block3 {
  font-family: "Mulish";
  padding: 12px 16px;
  color: #002541;
  background-color: #c2ffe3;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  background: src("/pooplogg/flower/2.png");
}

.d-card-subtitle {
  font-size: 12px;
  font-weight: 600;
}

.serviceCards {
  /* width: 100%; */
  margin-bottom: 20px;
  min-height: 120px;
  padding: 15px;
  border-radius: 3px;
  background-color: #fbfbfb;
  border: 1px solid #d9d9d9;
}

.serviceCards2 {
  /* width: 100%; */
  margin-bottom: 20px;
  min-height: 120px;
  padding: 15px;
  border-radius: 3px;
  background-color: #002541;
  /* border: 1px solid #d9d9d9; */
}
.textBackStyle {
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
  cursor: pointer;
  color: #134468;
}

.input-label {
  font-size: 14px;
  font-weight: 600;
}

.serviceInfoCard {
  padding: 15px 30px;
  padding-bottom: 42px;
  border-radius: 3px;
  background-color: #fbfbfb;
  border: 1px solid #d9d9d9;
}

.c-badge-success {
  text-align: center;
  background-color: #dbffef;
  color: #11d5b4;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.c-badge-danger {
  text-align: center;
  background-color: #fff0ec;
  color: #ff0000;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.c-badge-primary {
  text-align: center;
  background-color: #def0ff;
  color: #134468;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}
.c-badge-secondary {
  text-align: center;
  background-color: #ebe7e7;
  color: #484848;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.c-badge-warning {
  text-align: center;
  background-color: #f8fece;
  color: #eeb809;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.customTable table td {
  background-color: #05c1b4 !important;
}

.evacTypeCard {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background-color: #fbfbfb;
  padding: 30px 47px;
  min-height: 420px;
  margin-bottom: 40px;
}

.additional-info-style {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background-color: #fbfbfb;
  padding: 30px 47px;
  /* min-height: 414px; */
  margin-bottom: 40px;
}

.evacCheckCard {
  border-radius: 3px;
  padding: 18px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.evacPackageCard1 {
  border-radius: 3px;
  padding: 35px 30px;
  cursor: pointer;
  display: flex;
  background: linear-gradient(90deg, #6b3bf6 0%, #3300c6 100%);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
}

.evacPackageCard2 {
  border-radius: 3px;
  cursor: pointer;
  padding: 35px 30px;
  display: flex;
  background: linear-gradient(87deg, #ffcd4a 0%, #eeac00 100%);
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 26px; */
}

.previewText {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Mulish", sans-serif;
}

.submit-btn-green {
  width: 140px;
  padding: 12px;
  font-size: 14px !important;
  font-weight: 500;
  color: #fff;
  background-color: var(--accent-green);
}
.submit-btn-red {
  width: 140px;
  padding: 12px;
  font-size: 14px !important;
  font-weight: 500;
  background-color: red;
}

.cancel-btn {
  width: 140px;
  padding: 12px;
  font-size: 14px !important;
  font-weight: 500;
  color: #fff;
  /* color: #212121; */
  background-color: darkgray;
}

.custom-label-new {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #00355b;
}

.custom-input-new {
  display: block !important;
  width: 100%;
  border-radius: 5px;
  border: 1.2px solid #00355b !important;
  padding: 15px 20px !important;
  box-sizing: border-box;
}

.custom-select-new {
  display: block !important;
  width: 100%;
  border-radius: 5px;
  border: 1.2px solid #00355b !important;
  padding: 15px 20px !important;
  box-sizing: border-box;
  padding-right: 40px; /* Add extra space for the custom arrow */
  border: 1px solid #00355b;
  position: relative;
  -webkit-appearance: none;
  background-image: url(/caret-down.svg);
  background-position: 100%;
  background-repeat: no-repeat;
  appearance: none; /* Remove the default arrow */
  /* background: url("path-to-your-arrow-icon.svg") no-repeat right center;  */
  background-size: 12px; /* Size of the custom arrow */
  background-position: right 10px center; /* Adjust position */
}

.custom-select-new ::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.progress-bar-container {
  width: 100%;
  height: 30px;
  background-color: #e0e0df;
  border-radius: 50px;
  margin: 10px 0;
}

.progress-bar {
  height: 100%;
  background-color: #00de74;
  text-align: center;
  line-height: 30px;
  color: white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  transition: width 0.3s ease-in-out;
}

.bg-card {
  background-color: #0380c6;
  border-radius: 10px;
}

.text-location {
  color: #00de74 !important;
  font-size: 16px;
}

.property {
  font-weight: bold;
  font-size: 16px;
}

.icon-circle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.line {
  background-color: #00de74;
  width: 49px;
  height: 3px;
}
.text-name {
  font-weight: bold;
}

.btn-send {
  background-color: #028fde;
  width: 100%;
  border-radius: 30px;
  border-color: transparent;
  cursor: pointer;
}

.icon-send {
  padding: 3px;
  border: 1px solid rgba(255, 255, 255, 0.81);
  border-radius: 50%;
}

.date-comp {
  padding: 10px 25px;
  border: 1px solid black;
  border-radius: 20px;
}
.icon-date {
  font-size: 21px;
}
.text-date {
  font-size: 14px;
}

.text-amount {
  font-weight: bold;
}
.amount-span-green {
  font-size: 24px;
  color: #00de74;
}
.box-green {
  background-color: #e1ffed;
  padding: 19px;
  border: 2.3px solid #00de74;
  border-radius: 10px;
  margin-bottom: 30px;
}
.year-text {
  font-weight: bold;
}
.amount-date {
  font-size: 20px;
  color: #00de74;
}
.amount-date-green {
  font-size: 20px;
  color: #00de74;
}
.amount-date-blue {
  font-size: 20px;
  color: #0098ed;
}

.box-blue {
  background-color: #e1faff;
  padding: 19px;
  border: 2.3px solid #0098ed;
  border-radius: 10px;
  margin-bottom: 30px;
}

.amount-span-blue {
  font-size: 24px;
  color: #0098ed;
}
.circle-green {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #d4ffe6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-blue {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #d4f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-btn {
  color: #266185;
  border: 1px solid #266185;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.bg-table {
  background-color: #f7f9ff;
}

.table-heady th {
  background-color: #f7f9ff !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-weight: 400;
}

.text-report {
  color: #266185;
  font-size: 12px;
  font-weight: bold;
}

/* table css */

.status-business {
  background-color: #eeac00;
  height: 8px;
  width: 100%;
  border-radius: 10px;
}

.text-table {
  color: #0380c6;
}

.table-body {
  font-size: 14px !important;
}

.btn-dropdown {
  border: 1px solid #002541;
}

/* OtpInput.css */
.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.otp-input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  outline: none;
}

.otp-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.submit-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.main-blue-text {
  color: #0056b3;
}

.main-blue-bg {
  background-color: #0056b3;
}

.ksText16 {
  font-size: 16px;
  font-weight: 600;
}

.ksText12 {
  font-size: 12px;
  font-weight: 600;
}

.ksText14 {
  font-size: 14px;
  font-weight: 600;
}

.ksText20 {
  font-size: 20px;
  font-weight: 600;
}

.ksText24 {
  font-size: 24px;
  font-weight: 600;
}

/* .weight {
  font-weight: 600;
} */
.weight800 {
  font-weight: 800;
}
.weight700 {
  font-weight: 700;
}
.weight400 {
  font-weight: 400;
}
.weight600 {
  font-weight: 600;
}

.rounded_boder_btn {
  display: inline-block;
  padding: 10px 10px 10px 24px;
  border: 1px solid #00355b;
  border-radius: 30px;
  cursor: pointer;
}

.rounded_boder_btn2 {
  padding: 30px 27px 35px 24px;
  border: 1px solid #00355b;
  border-radius: 12px;
  cursor: pointer;
}
